<template>
  <div class="content content_in">
    <div class="title-row row justify-content-between">
      <div class="col-12 col-auto">
        <h1>{{ $t("finances.payment_order") }}</h1>
      </div>
    </div>
    <template v-if="showWallets">
      <div class="no-wallets" v-if="!payments.length">
        <img src="../assets/images/icons/card.svg" alt="" />
        {{ $t("profile.no_wallets") }}
        <router-link to="/profile">{{ $t("profile.page") }}</router-link>
      </div>
      <div class="pay-method" v-if="payments.length">
        <a class="pay-method__item" v-for="(payment, index) in payments" :class="{ active: currentPayment == index }" :key="index" :style="{ borderColor: payment.style }" @click="setPayment(index)">
          <div
            class="pay-method__icon"
            :style="{
              background: payment.onlyBorder ? 'transparent' : payment.style,
            }"
          >
            <img :src="payment.url" :class="{ circle: payment.id === 'payoneer' }" />
          </div>
          <div class="pay-method__title">
            {{ getPaymentTitle(payment) }}
          </div>
          <div class="pay-method__desc">{{ formatAddress(payment) }}</div>
        </a>
      </div>
      <div class="tubs-content max-660" v-if="payments.length">
        <div class="tubs-content__item active" data-tub="capitalist" data-group="method">
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="form-item pb-2">
                <div class="form-item__label">{{ $t("finances.sum") }} $</div>
                <div class="form-item__field">
                  <input @input="checkValidity" v-model="sum" inputmode="numeric" pattern="[0-9]*" type="number" maxlength="10" :placeholder="$t('finances.min50')" />
                </div>
              </div>
            </div>
            <!-- todo: revert later -->
            <!-- <div class="col-12 col-md-6 align-self-end pb-md-2">
              <div class="form-item pb-2">
                <div class="form-item__field">
                  <label class="checkbox">
                    <input v-model="fast" type="checkbox" />
                    <span class="checkbox__visible"></span>

                    <span class="checkbox__text">
                      {{ $t("finances.early_payout") }} (-5%)
                      <svg
                        v-svg
                        style="fill: #5183cf"
                        symbol="info"
                        size="0 0 20 18"
                        role="presentation"
                        v-tooltip="{
                          content: $t('finances.tooltip'),
                          bottom: true,
                          offset: '10',
                          triggers: ['hover', 'click'],
                        }"
                      ></svg>
                    </span>
                  </label>
                </div>
              </div>
            </div> 
             <InformationBlock
              v-if="payments[currentPayment].id === 'usdt'"
              class="col-12"
              style="padding-bottom: 32px"
            >
              {{ $t("finances.commission") }}
              <span style="color: #c83b3b; font-weight: 600"> 3% </span>
            </InformationBlock> -->
            <div class="col-12 mb-5">
              <button :disabled="!sum || Number(sum) < 50" @click="withdrawFromWallet" class="btn _mob100 _bg-green">
                {{ $t("finances.create_order") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="showInfo">
      <div v-if="finances.length" class="c-table mt-5 mt-md-0">
        <div class="inner">
          <table class="table _no-wrap sortEnable tablesorter tablesorter-bootstrap" role="grid">
            <thead>
              <tr>
                <th>ID</th>
                <th>{{ $t("finances.requisites") }}</th>
                <th>{{ $t("finances.sum") }}</th>
                <th>{{ $t("finances.bonus") }}</th>
                <th width="170">{{ $t("finances.order_date") }}</th>
                <th width="170">{{ $t("finances.payout_date") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in finances" :key="index">
                <td>{{ item.id }}</td>
                <td>{{ item.details }}</td>
                <td class="bg-green">
                  <span class="font-weight-bold">{{ item.amount }}</span>
                </td>
                <td>
                  <span class="font-weight-bold">{{ item.bonus }}</span>
                </td>
                <td>{{ item.request_date }}</td>
                <td>
                  {{ item.status === 1 ? item.pay_date : item.status === 2 ? $t("finances.cancelled") : $t("finances.in_progress") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else class="no-information">
        <svg v-svg symbol="cash" role="presentation"></svg>

        <h3>{{ $t("finances.no_payouts") }}</h3>
        <p>{{ $t("finances.no_payouts_info") }}</p>
      </div>
    </template>
    <alert v-if="$alert.title" />
  </div>
</template>

<script>
import "@/assets/css/_no-information.scss";
import PanelService from "@/api/servicePanel";
// import InformationBlock from "@/components/InformationBlock.vue";
export default {
  name: "Finances",
  beforeMount() {
    document.title = `Partners.House | ${this.$t("finances.name")}`;
  },
  beforeUpdate() {
    document.title = `Partners.House | ${this.$t("finances.name")}`;
  },
  // components: {
  //   InformationBlock,
  // },
  data() {
    return {
      currentPayment: 0,
      sum: null,
      fast: false,
      finances: [],
      wallets: [],
      payments: [],
      showInfo: false,
      showWallets: false,
    };
  },
  mounted() {
    this.getWallets();
    PanelService.getFinancesHistory()
      .then((res) => {
        if (res.status === 200) {
          this.finances = res.data;
        }
      })
      .finally(() => {
        this.showInfo = true;
      });
  },
  methods: {
    getPaymentTitle(payment) {
      if (payment.id.includes("card")) {
        return this.$formatLabel(`finances.${payment.id}`);
      } else {
        return payment.name;
      }
    },
    formatAddress(payment) {
      if (payment.description.length > 14) {
        return payment.description.slice(0, 5) + "..." + payment.description.slice(-5, payment.description.length);
      } else {
        return payment.description;
      }
    },
    getWallets() {
      PanelService.getWallets()
        .then((res) => {
          if (res && res.status === 200) {
            if (res.data.length) {
              this.wallets = res.data;
              this.setPaymentOptions();
            }
          }
        })
        .finally(() => {
          this.showWallets = true;
        });
    },
    setPaymentOptions() {
      this.wallets.map((wallet) => {
        switch (wallet.type) {
          case "capitalist":
            this.payments.push({
              name: "Capitalist",
              id: "capitalist",
              description: wallet.wallet,
              style: "#3aa458",
              url: require("@/assets/images/icons/capitalist.svg"),
            });
            break;
          case "ph":
            this.payments.push({
              name: "Push House",
              id: "ph",
              description: wallet.wallet,
              style: "#c93636",
              url: require("@/assets/images/icons/push.svg"),
            });
            break;
          case "webmoney":
            this.payments.push({
              name: "WebMoney",
              id: "webmoney",
              description: wallet.wallet,
              style: "#0277be",
              url: require("@/assets/images/icons/webmoney.svg"),
            });
            break;
          // case "qiwi":
          //   this.payments.push({
          //     name: "Qiwi",
          //     id: "qiwi",
          //     description: wallet.wallet,
          //     style: "#ff8c00",
          //     url: require("@/assets/images/icons/qiwi.svg"),
          //   });
          //   break;
          case "card":
            this.payments.push({
              name: this.$t("finances.card"),
              id: "card",
              description: wallet.wallet,
              style: "#691d79",
              url: require("@/assets/images/icons/card.svg"),
            });
            break;
          case "cardRu":
            this.payments.push({
              name: this.$t("finances.cardRu"),
              id: "cardRu",
              description: wallet.wallet,
              style: "#691d79",
              url: require("@/assets/images/icons/card.svg"),
            });
            break;
          case "cardKz":
            this.payments.push({
              name: this.$t("finances.cardKz"),
              id: "cardKz",
              description: wallet.wallet,
              style: "#1f7b7b",
              url: require("@/assets/images/icons/card.svg"),
            });
            break;
          case "yandex":
            this.payments.push({
              name: "YooMoney",
              id: "yandex",
              description: wallet.wallet,
              style: "#8a3ffd",
              url: require("@/assets/images/icons/yoomoney.svg"),
            });
            break;
          case "usdt":
            this.payments.push({
              name: "USDT",
              id: "usdt",
              description: wallet.wallet,
              style: "#3aa458",
              onlyBorder: true,
              url: require("@/assets/images/icons/tether.svg"),
            });
            break;
          case "payoneer":
            this.payments.push({
              name: "Payoneer",
              id: "payoneer",
              description: wallet.wallet,
              style: "#3aa458",
              onlyBorder: true,
              url: require("@/assets/images/icons/payoneer.png"),
            });
            break;
          default:
            return false;
        }
      });
    },
    formatLabel(el) {
      return this.$t(el);
    },
    withdrawFromWallet() {
      if (Number(this.sum) < 50) {
        this.$alert({
          title: this.$t("sent_error"),
          text: this.$t("finances.sum_error"),
        });

        return;
      }
      const payload = {
        fast: this.fast ? 1 : 0,
        amount: Number(this.sum),
        wallet: this.payments[this.currentPayment].id,
      };

      PanelService.withdrawFromWallet(payload).then((res) => {
        if (res && res.status === 200) {
          this.$alert({
            title: res.message,
            type: "success",
          });

          window.location.reload();
        }
        if (res && res.status === 400) {
          this.$alert({
            title: this.$t("sent_error"),
            text: res.data.amount || res.data.wallet,
          });
        }
      });
    },
    checkValidity($event) {
      let reg = /^[0-9]+(\.[0-9]*)?$/;
      if (this.sum.match(reg)) return this.sum;
      else this.sum = this.sum.replace($event.data, "");
    },
    setPayment(index) {
      this.currentPayment = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.no-wallets {
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
  a {
    color: #4040ef;
  }
  img {
    display: block;
    margin: 0 auto 20px;
    width: 60px;
    filter: brightness(0.5);
  }
}
.pay-method {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
@media screen and (max-width: 1000px) {
  .pay-method {
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 7px;
  }
}

.pay-method__item {
  width: 190px;
  min-width: 190px;
  margin: 0 8px 15px;
  border-radius: 10px;
  height: 66px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid transparent;
  padding-left: 65px;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
}
.pay-method__item:not(.active) {
  background: #edeff4;
  border-color: transparent !important;
}
.pay-method__item:not(.active) .pay-method__icon {
  background: #fff !important;
}
.pay-method__item:not(.active) .pay-method__icon img {
  filter: brightness(0);
}
.pay-method__item .pay-method__title {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 2px;
}
.pay-method__item .pay-method__desc {
  font-size: 14px;
}
.pay-method__item .pay-method__icon {
  position: absolute;
  left: 15px;
  top: 50%;
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}
.pay-method__item .pay-method__icon img {
  transition: 0.3s;
  width: 26px;
  height: 20px;
  &.circle {
    width: 22px;
    height: 22px;
  }
}
</style>
